import React from 'react';

const footer = () => {
    return (
        <footer className="techinspect-footer main-container">
            <p className="copyright-text">&copy; MYINSPECT</p>
        </footer>
    );
};

export default footer;