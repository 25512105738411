import React from 'react';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { months } from '../../../data';

const InspectionDate = ({ data, switchBlock, formData }) => {
	const { t } = useTranslation();
	const date = data.inspectDate;
	const insDay = Number(date.substr(0, 2));
	const insMonth = t(months[Number(date.substr(3, 2)) - 1]);
	const insYear = Number(date.substr(6, 4));
	return (
		<div className="techinspect-result">
			<h4 className="tech-date">
				{insDay + ' ' + insMonth + ', ' + insYear + ' ' + t('year')}
			</h4>
			<div className="tech-price">
				{ReactHTMLParser(t('serviceFee'))}
				<span className="price">60₾</span>
			</div>
			<div className="tech-btns">
				<button
					className="tech-submit-btn tech-notification-btn"
					onClick={() => switchBlock('reminderForm')}>
					{t('reminder')}
				</button>
				<Link
					to={{
						pathname: '/centers',
						state: {
							ln: formData.license_number,
							id: date,
							category: formData.category
						}
					}}>
					<button className="tech-submit-btn tech-order-btn">
						{t('reserveVisit')}
					</button>
				</Link>
			</div>
		</div>
	);
};
export default InspectionDate;
