import React from 'react';
import './style.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LocationPageHandler from './containers/LocationPageHandler';
import Wrapper from './HOC/Wrapper';
import MainPage from './components/MainPage/MainPage';
import Offers from './components/Offers/Offers';
import Header from './components/Layout/Header';

const App = () => {
	const header = <Header />;

	return (
		<Wrapper>
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<div className="techinspect">
							<div className="main-wrapper full-width">
								<div className="techinspect-wrapper">
									{header}
									<MainPage />
								</div>
							</div>
						</div>
					</Route>
					<Route exact path="/centers">
						<div className="location">
							<div className="location-wrapper">
								{header}
								<LocationPageHandler />
							</div>
						</div>
					</Route>
					<Route exact path="/offers">
						<div className="location">
							<div className="location-wrapper">
								{header}
								<Offers />
							</div>
						</div>
					</Route>
				</Switch>
			</BrowserRouter>
		</Wrapper>
	);
};

export default App;
