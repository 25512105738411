import React from 'react';
import {useTranslation} from "react-i18next";

const OrderFooter = (props) => {
    const {t} = useTranslation();

    return (
        <div className="order-footer">
            <div className="order-row order-footer-row">
                    <div className="order-col order-footer-col">
                        <ul className="order-steps-list">
                            <li className={`${props.step === 1 && 'active'} ${props.step > 1 && 'success'}`}>
                                <span>01</span>{t('car')}
                            </li>
                            <li className={`${props.step === 2 && 'active'} ${props.step > 2 && 'success'}`}>
                                <span>02</span>{t('personalInfo')}
                            </li>
                            <li className={`${props.step === 3 && 'active'} ${props.step > 3 && 'success'}`}>
                                <span>03</span>{t('date')}
                            </li>
                        </ul>
                    </div>
                    <div className="order-col order-footer-col">
                        <div className="order-steps-btns">
                            <button
                                onClick={() => props.prevStep()}
                                className="tech-back-btn tech-check-back-btn"
                            />
                            <button
                                type="submit"
                                onClick={() => props.setDirection('forward')}
                                className={`tech-submit-btn tech-next-btn ${props.submitting && 'isLoading'} ${props.disabled && 'disabled'}`}
                            >
                                {props.title}
                            </button>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default OrderFooter;