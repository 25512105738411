import React from 'react';
import {ReactComponent as CardSuccess} from "../../../assets/svg/card-success.svg";
import {ReactComponent as CardWarning} from "../../../assets/svg/card-warning.svg";
import TechinspectCar from "../../../assets/images/techinspect-car.png";

const carSideContent = (props) => {
    return (
        <div className="car-bg">
            <div className="tech-date-cards">
                <div
                    className="tech-date-card success-card card-hovered disabled">
                    <div className="tech-date-icon">
                        <i className="success-icon"><CardSuccess/></i>
                        <i className="warning-icon"><CardWarning/></i>
                    </div>
                    <div className="tech-card-lines">
                        <div className="tech-card-line line-md"></div>
                        <div className="tech-card-line line-lg"></div>
                    </div>
                </div>
                <div
                    className="tech-date-card success-card card-hovered active-card">
                    <div className="tech-date-icon">
                        <i className="success-icon"><CardSuccess/></i>
                        <i className="warning-icon"><CardWarning/></i>
                    </div>
                    <div className="tech-card-lines">
                        <div className="tech-card-line line-md"></div>
                        <div className="tech-card-line line-lg"></div>
                    </div>
                </div>
                <div
                    className="tech-date-card success-card card-hovered disabled">
                    <div className="tech-date-icon">
                        <i className="success-icon"><CardSuccess/></i>
                        <i className="warning-icon"><CardWarning/></i>
                    </div>
                    <div className="tech-card-lines">
                        <div className="tech-card-line line-md"></div>
                        <div className="tech-card-line line-lg"></div>
                    </div>
                </div>
            </div>
            <div className="techinspect-car">
                <img alt="car" src={TechinspectCar}/>
                <div className="car-road"></div>
            </div>
        </div>
    );
};

export default carSideContent;