import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import axios from 'axios';
import { CookiesProvider } from 'react-cookie';
import ReminderProvider from '../src/context/reminder-context';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;

ReactDOM.render(
	<ReminderProvider>
		<CookiesProvider>
			<Suspense fallback={''}>
				<App />
			</Suspense>
		</CookiesProvider>
	</ReminderProvider>,
	document.getElementById('container')
);
