import axios from 'axios';

export let vehicleTypes = [];

axios.post(process.env.REACT_APP_API_SERVER + '/GetTechCategories')
    .then(res => {
        let cats = res.data.categories;
        for (let category of cats) {
            let catName = Object.values(JSON.parse(category.name))[0];
            let catValue = catName.substr(0, 2);
            vehicleTypes.push({
                value: catValue,
                label: catName
            });
        }
    });

export const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
];

export const langMap = {
    'ka': 4,
    'en': 1,
    'ru': 5
};