import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {ReminderContext} from "../../../context/reminder-context";

const ReserveHeading = (props) => {
    const selectedCenter = useContext(ReminderContext).selectedCenter;
    const {t} = useTranslation();

    return (
        <div className="order-heading">
            <div className="order-info-heading">
                <div className="order-row">
                    <div className="order-col">
                        <h2>{t('fillInfo')}</h2>
                    </div>
                    <div className="order-col">
                        <div className="location-item-heading">
                            <div className="location-icon">
                                <img src={
                                    Number(selectedCenter.logos) ? process.env.REACT_APP_STATIC_URL + 'logos/' + selectedCenter.id + '.jpg' :
                                        process.env.REACT_APP_NO_LOGO} alt={selectedCenter.title}/>
                            </div>
                            <div className="location-info">
                                <h4>{selectedCenter.title}</h4>
                                <h6>{selectedCenter.address}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReserveHeading;