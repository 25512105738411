import React, { useEffect, useState } from 'react';
import './OffersSideContent.scss';
import OfferCard from './OfferCard';

const OffersSideContent = () => {
	const [offers, setOffers] = useState(null);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_SERVER}/gettechofferslist`)
			.then(r => r.json())
			.then(data => setOffers(data.data));
	}, []);

	return (
		<div className="offers-side">
			<div className="offers-side-content">
				<div className="offers-content-inner">
					<div className="offers-heading">
						<p className="offers-text">
							აირჩიე სასურველი შეთავაზება და დაჯავშნე ვიზიტი
						</p>
					</div>
					<div className="offers-block-wrapper">
						<div className="offers-block">
							<div className="offers-row">
								{offers
									? offers.map(offer => (
											<OfferCard
												key={offer.id}
												offers={offers}
												unlimitedOffer={
													Number(
														offer.usage_limit
													) === -1
												}
												offer={offer}
											/>
									  ))
									: null}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*<div className="offers-footer">*/}
			{/*  <div className="offers-side-content">*/}
			{/*    <button type="submit" className="offers-order-btn">დაჯავშნე ვიზიტი</button>*/}
			{/*  </div>*/}
			{/*</div>*/}
		</div>
	);
};

export default OffersSideContent;
