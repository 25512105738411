import React from 'react';
import {useTranslation} from "react-i18next";
import {ReactComponent as TechPhoneSVG} from "../../../assets/svg/tech-phone.svg";
import {ReactComponent as TechClockSVG} from "../../../assets/svg/tech-clock.svg";
import {ReminderContext} from "../../../context/reminder-context";

const TechCenter = (props) => {
    const {t} = useTranslation();
    const setSelected = React.useContext(ReminderContext).setSelectedCenter;

    const reserveButtonHandler = (center) => {
        setSelected(props.center);
        props.startReserve(true);
    };

    return (
        <div className="location-item main-container-left"
             onMouseOver={() => setSelected(props.center)}
             onMouseLeave={() => setSelected(null)}
        >
            <div className="location-address">
                <div className="location-icon">
                    <img
                        src={Number(props.center.logos) ? process.env.REACT_APP_STATIC_URL + 'logos/' + props.center.id + '.jpg' : process.env.REACT_APP_NO_LOGO}
                        alt={props.center.title}/>
                </div>
                <div className="location-title">
                    <h4>{props.title}</h4>
                    <h6>{props.address}</h6>
                </div>
            </div>
            <div className="location-info">
                <ul className="location-info-list">
                    <li className="location-info-item">
                        <i><TechPhoneSVG/></i>
                        <span className="phone">{props.phone}</span>
                    </li>
                    <li className="location-info-item">
                        <i><TechClockSVG/></i>
                        <span>{`ორშ-პარ - ${props.center.weekDayHours}`}</span>
                    </li>
                    <li className="location-info-item">
                        <i><TechClockSVG/></i>
                        <span>{`შაბათი - ${props.center.satDayHours}`}</span>
                    </li>
                    <li className="location-info-item">
                        <i><TechClockSVG/></i>
                        <span>{`კვირა - ${props.center.sunDayHours}`}</span>
                    </li>
                </ul>

            </div>
            <div className="location-btn">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="btn-cancel">{t('cancel')}</a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="btn-order" onClick={() => reserveButtonHandler(props.center)}>{t('reserve')}</a>
            </div>
        </div>
    );
};

export default TechCenter;