import React from 'react';
import PhoneSideContent from './RightContent/PhoneSideContent';
import OffersSideContent from './RightContent/OffersSideContent';
import CarSideContent from './RightContent/CarSideContent';

const rightInspection = ({ selectedComponent }) => {
	return (
		<div className="right-techinspect">
			{selectedComponent === 'reminderForm' ? (
				<PhoneSideContent />
			) : selectedComponent === 'inspectionDate' ? (
				<OffersSideContent />
			) : (
				<CarSideContent />
			)}
		</div>
	);
};

export default rightInspection;
