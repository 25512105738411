import React, { useRef, useState, useContext } from 'react';
import { ReactComponent as UserBgSVG } from '../../../../assets/svg/user-bg.svg';
import { ReactComponent as EmailBgSVG } from '../../../../assets/svg/email-bg.svg';
import { ReactComponent as PhoneBgSVG } from '../../../../assets/svg/phone-bg.svg';
import { ReactComponent as SMSBgSVG } from '../../../../assets/svg/sms-bg.svg';
import { ReactComponent as SendSVG } from '../../../../assets/svg/send.svg';
import { ReactComponent as TrySVG } from '../../../../assets/svg/try.svg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ReserveHeading from '../ReserveHeading';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import { useTranslation } from 'react-i18next';
import OrderFooter from '../OrderFooter';
import Select from 'react-select';
import { vehicleTypes } from '../../../../data';
import { ReminderContext } from '../../../../context/reminder-context';
import Popup from '../../../UI/popups/popup';

const Step2 = ({ formData, setFormData, nextStep, prevStep }) => {
	const [direction, setDirection] = useState('back');
	const [codeSent, updateCodeSentValue] = useState(false);
	const { t } = useTranslation();
	const smsCodeBtn = useRef(null);
	const updPhone = useContext(ReminderContext).setPhone;
	const updEmail = useContext(ReminderContext).setEmail;

	const stepTwoForm = useFormik({
		initialValues: formData,
		validationSchema: yup.object({
			first_name: yup
				.string()
				.required(t('errorRequired'))
				.min(2, t('errorFirstName'))
				.max(20, t('errorFirstName')),
			email: yup
				.string()
				.email(t('errorInvalid'))
				.required(t('errorRequired')),
			phone: yup
				.string()
				.length(9, t('errorPhone'))
				.required(t('errorRequired')),
			sms_code: yup
				.string()
				.length(5, t('errorSMSCode'))
				.required(t('errorRequired'))
		}),
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true);
			setFormData(values);

			axios
				.post(
					`/checksmscode?phone=${values.phone}&sms_code=${values.sms_code}`
				)
				.then(res => {
					if (res.data.statusCode === 1) {
						direction === 'back' ? prevStep() : nextStep();
					} else {
						swal({
							buttons: {},
							content: (
								<Popup
									type={'error'}
									title={res.data.statusMessage}
								/>
							),
							showCloseButton: true,
							timer: 2000
						});
					}

					setSubmitting(false);
				});
		}
	});

	const handleCodeSend = () => {
		if (
			smsCodeBtn.current.classList.contains('disabled') ||
			!stepTwoForm.touched.phone ||
			stepTwoForm.errors.phone
		) {
			return false;
		}

		axios
			.post('/sendactivationcode?phone=' + stepTwoForm.values.phone)
			.then(res => {
				if (res.data.statusCode === 1) {
					updateCodeSentValue(true);

					swal({
						buttons: {},
						content: (
							<Popup
								type={'success'}
								title={res.data.statusMessage}
							/>
						),
						showCloseButton: true,
						timer: 2000
					});

					setInterval(() => updateCodeSentValue(false), 60000);
				} else {
					swal({
						buttons: {},
						content: (
							<Popup
								type={'error'}
								title={res.data.statusMessage}
							/>
						),
						showCloseButton: true,
						timer: 2000
					});
				}
			});
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			color: state.isSelected || state.isFocused ? '#FD4100' : '#181a26',
			backgroundColor: 'transparent',
			cursor: 'pointer',
			padding: '12px 41px 12px 28px',
			fontFamily: 'FiraGO',
			fontSize: '13px'
		}),
		control: () => ({
			width: '100%',
			height: '70px',
			display: 'flex',
			alignItems: 'center',
			fontFamily: 'FiraGO',
			fontSize: '14px',
			color: 'rgba(#181a26, 0.6)',
			border: '1px solid #dadce0',
			borderRadius: '8px',
			padding: '0 32px 0 78px'
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		}
	};

	return (
		<div className="order-details main-container">
			<div className="order-body-wrapper">
				<form
					className="order-form"
					onSubmit={stepTwoForm.handleSubmit}>
					<ReserveHeading />
					<div className="order-body">
						<div className="order-body-inner">
							<div className="order-row">
								<div className="order-col">
									<div className="tech-input car-number-input">
										<input
											type="text"
											value={formData.license_number}
											disabled
											style={{ cursor: 'no-drop' }}
										/>
									</div>
								</div>
								<div className="order-col">
									<div className="vehicle__type__select">
										<Select
											options={vehicleTypes}
											isDisabled
											defaultValue={formData.category}
											styles={customStyles}
											placeholder={t('chooseCategory')}
											className="vehicle__type__select"
										/>
									</div>
								</div>
							</div>
							<div className="order-row">
								<div className="order-col">
									<div
										className={`tech-input
                                    ${
										stepTwoForm.touched.first_name &&
										stepTwoForm.errors.first_name
											? 'error'
											: null
									}
                                    ${
										!stepTwoForm.errors.first_name &&
										stepTwoForm.touched.first_name
											? 'valid'
											: null
									}`}>
										<i>
											<UserBgSVG />
										</i>
										<input
											type="text"
											name="first_name"
											value={
												stepTwoForm.values.first_name
											}
											onChange={stepTwoForm.handleChange}
											onBlur={stepTwoForm.handleBlur}
											placeholder={t('firstName')}
										/>
										{stepTwoForm.touched.first_name &&
											stepTwoForm.errors.first_name && (
												<span className="error-message">
													{
														stepTwoForm.errors
															.first_name
													}
												</span>
											)}
									</div>
								</div>
								<div className="order-col">
									<div
										className={`tech-input
                                    ${
										stepTwoForm.touched.email &&
										stepTwoForm.errors.email
											? 'error'
											: null
									}
                                    ${
										!stepTwoForm.errors.email &&
										stepTwoForm.touched.email
											? 'valid'
											: null
									}`}>
										<i>
											<EmailBgSVG />
										</i>
										<input
											type="email"
											name="email"
											onChange={e => {
												stepTwoForm.handleChange(e);
												updEmail(e.target.value);
											}}
											onBlur={stepTwoForm.handleBlur}
											placeholder={t('email')}
										/>
										{stepTwoForm.touched.email &&
											stepTwoForm.errors.email && (
												<span className="error-message">
													{stepTwoForm.errors.email}
												</span>
											)}
									</div>
								</div>
							</div>
							<div className="order-row">
								<div className="order-col">
									<div
										className={`tech-input
                                    ${
										stepTwoForm.touched.phone &&
										stepTwoForm.errors.phone
											? 'error'
											: null
									}
                                    ${
										!stepTwoForm.errors.phone &&
										stepTwoForm.touched.phone
											? 'valid'
											: null
									}`}>
										<i>
											<PhoneBgSVG />
										</i>
										<input
											type="number"
											name="phone"
											value={stepTwoForm.values.phone}
											onChange={e => {
												stepTwoForm.handleChange(e);
												updPhone(e.target.value);
											}}
											onBlur={stepTwoForm.handleBlur}
											placeholder={t('phone')}
										/>
										{stepTwoForm.errors.phone &&
											stepTwoForm.touched.phone && (
												<span className="error-message">
													{stepTwoForm.errors.phone}
												</span>
											)}
									</div>
								</div>
								<div className="order-col">
									<div className="tech-input-group">
										<div
											className={`tech-input sms-input
                                        ${
											stepTwoForm.touched.sms_code &&
											stepTwoForm.errors.sms_code
												? 'error'
												: null
										}
                                        ${
											!stepTwoForm.errors.sms_code &&
											stepTwoForm.touched.sms_code
												? 'valid'
												: null
										}`}>
											<i>
												<SMSBgSVG />
											</i>
											<input
												type="text"
												placeholder={t('smsCode')}
												name="sms_code"
												autoComplete={'off'}
												value={
													stepTwoForm.values.sms_code
												}
												onChange={
													stepTwoForm.handleChange
												}
												onBlur={stepTwoForm.handleBlur}
											/>
											{stepTwoForm.errors.sms_code &&
												stepTwoForm.touched
													.sms_code && (
													<span className="error-message">
														{
															stepTwoForm.errors
																.sms_code
														}
													</span>
												)}
										</div>
										<button
											ref={smsCodeBtn}
											onClick={handleCodeSend}
											className={`btn sms-btn ${(stepTwoForm
												.errors.phone ||
												!stepTwoForm.values.phone ||
												codeSent) &&
												'disabled'}`}
											type="button">
											<span
												className={`send-code ${codeSent &&
													'fill'}`}>
												{t('clickForCode')}
												<i>
													<SendSVG />
												</i>
											</span>
											<span
												className="resend-code"
												style={{ display: 'none' }}>
												{t('resendCode')}
												<i>
													<TrySVG />
												</i>
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<OrderFooter
						submitting={stepTwoForm.isSubmitting}
						disabled={
							stepTwoForm.isSubmitting ||
							Object.entries(stepTwoForm.touched).length === 0 ||
							Object.entries(stepTwoForm.errors).length > 0
						}
						title={t('continue')}
						prevStep={prevStep}
						setDirection={setDirection}
						step={2}
					/>
				</form>
			</div>
		</div>
	);
};

export default Step2;
