import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MapArrow from '../../../assets/svg/map-arrow.svg';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import MarkerIcon from '../../../assets/svg/filled-circle.svg';
import { ReminderContext } from '../../../context/reminder-context';

const LocationMap = props => {
	const { t } = useTranslation();
	const [mapClosed, setMapClosed] = useState(false);
	const [viewport, setViewport] = useState({
		width: 800,
		height: 800,
		latitude: 41.7256661,
		longitude: 44.7405821,
		zoom: 8
	});
	const setSelectedCenter = React.useContext(ReminderContext)
		.setSelectedCenter;
	const selected = React.useContext(ReminderContext).selectedCenter;
	const mapConfig = {
		mapStyle: 'https://map.my.ge/style/my/map/style.json',
		onViewportChange: setViewport
	};

	const mapArrowClickHandler = () => {
		props.mapClicked();
		setMapClosed(!mapClosed);
	};

	return (
		<div
			className={`right-location ${mapClosed ? 'map-close' : undefined}`}>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a className="map-resize" onClick={() => mapArrowClickHandler()}>
				<span className="map-arrow">
					<i>
						<img src={MapArrow} alt="Map Arrow" />
					</i>
				</span>
			</a>
			<ReactMapGL {...viewport} {...mapConfig}>
				{props.centers.map(center => {
					return (
						<Marker
							key={`marker-${center.id}`}
							latitude={parseFloat(center.map_lan)}
							longitude={parseFloat(center.map_lon)}
							captureClick={true}>
							<img
								onClick={() => setSelectedCenter(center)}
								src={MarkerIcon}
								alt="marker"
							/>
						</Marker>
					);
				})}

				{selected && (
					<Popup
						key={selected.id}
						latitude={parseFloat(selected.map_lan)}
						longitude={parseFloat(selected.map_lon)}
						onClose={() => setSelectedCenter(null)}>
						<div className="map-popover">
							<div className="map-popover-img">
								<img
									src={
										Number(selected.covers)
											? process.env.REACT_APP_STATIC_URL +
											  'covers/' +
											  selected.id +
											  '.jpg'
											: process.env.REACT_APP_NO_COVER
									}
									alt={selected.title}
								/>
							</div>
							<div className="map-popover-body">
								<div className="map-working-item">
									<span className="working-days">
										{t('weekWorkDays')}
									</span>
									<span className="working-hours">
										{selected.weekDayHours}
									</span>
								</div>
								<div className="map-working-item">
									<span className="working-days">
										{t('satWorkDays')}
									</span>
									<span className="working-hours">
										{selected.satDayHours}
									</span>
								</div>
								<div className="map-working-item">
									<span className="working-days">
										{t('sunWorkDays')}
									</span>
									<span className="working-hours">
										{selected.sunDayHours}
									</span>
								</div>
							</div>
						</div>
					</Popup>
				)}
			</ReactMapGL>
		</div>
	);
};

export default LocationMap;
