import React from 'react';

export const ReminderContext = React.createContext(null);

export default props => {
	const [phone, setPhone] = React.useState(null);
	const [phoneRem, setPhoneRem] = React.useState(null);
	const [email, setEmail] = React.useState(null);
	const [emailRem, setEmailRem] = React.useState(null);
	const [selectedCenter, setSelectedCenter] = React.useState(null);
	const [formData, setFormData] = React.useState(null);
	let [chosenOffers, setChosenOffers] = React.useState([]);

	const selectOffer = (e, id, usage_limit) => {
		const offerIsChecked = e.target.checked;
		const alreadySelected = chosenOffers.find(el => el.id === id);

		if (offerIsChecked) {
			!alreadySelected && chosenOffers.push({ id, usage_limit });
		} else {
			chosenOffers = chosenOffers.filter(el => el.id !== id);
		}

		// let limitedOffers = chosenOffers.filter(el => el.usage_limit > -1);
		// if (limitedOffers.length > 1) {
		// 	let firstCheckedLimitedOffer = limitedOffers.shift();
		// 	chosenOffers = chosenOffers.filter(
		// 		el => el.id !== firstCheckedLimitedOffer.id
		// 	);
		// }
		setChosenOffers(chosenOffers);
	};

	return (
		<ReminderContext.Provider
			value={{
				phone,
				email,
				selectedCenter,
				setSelectedCenter,
				setPhone,
				setEmail,
				phoneRem,
				setPhoneRem,
				emailRem,
				setEmailRem,
				formData,
				setFormData,
				chosenOffers,
				setChosenOffers,
				selectOffer
			}}>
			{props.children}
		</ReminderContext.Provider>
	);
};
