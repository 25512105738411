import React, { useEffect, useState } from 'react';
import { ReactComponent as OfferIcon } from '../../assets/svg/offers.svg';
import './Offers.scss';
import OfferItem from './Offer-item';
import { useHistory } from 'react-router-dom';

const Offers = () => {
	const [offers, setOffers] = useState(null);
	const history = useHistory();

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_SERVER}/gettechofferslist`)
			.then(r => r.json())
			.then(data => setOffers(data.data));
	}, []);

	return (
		<div className="offers-wrapper">
			<div className="offers-left">
				<div className="offers-content">
					<div className="offers-content-inner">
						<div className="offers-heading">
							<h2 className="offers-title">შეთავაზებები</h2>
							<p className="offers-text">
								აირჩიე სასურველი შეთავაზება და დაჯავშნე ვიზიტი
							</p>
						</div>
						<div className="offers-block-wrapper">
							<div className="offers-block">
								<div className="offers-row">
									{offers
										? offers.map(offer => (
												<OfferItem
													key={offer.id}
													offers={offers}
													unlimitedOffer={
														Number(
															offer.usage_limit
														) === -1
													}
													offer={offer}
												/>
										  ))
										: null}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="offers-footer show">
					<div className="offers-content">
						<button
							onClick={() => history.push('/')}
							style={{ cursor: 'pointer' }}
							type="submit"
							className="offers-next-btn">
							გაგრძელება
						</button>
					</div>
				</div>
			</div>
			<div className="offers-right">
				<div className="offers-box">
					<div className="offers-icon">
						<OfferIcon />
					</div>
					<div className="offers-subtitle">
						MY inspect - ის შეთავაზებები
					</div>
				</div>
			</div>
		</div>
	);
};

export default Offers;
