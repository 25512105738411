import React, { useState, useContext } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import momentLoc from 'moment-with-locales-es6';
import MarkerIcon from '../../../assets/svg/filled-circle.svg';
import { ReactComponent as SuccessSentSVG } from '../../../assets/svg/success-sent.svg';
import { ReactComponent as EmailShapeSVG } from '../../../assets/svg/email-shape.svg';
import { ReactComponent as SMSShapeSVG } from '../../../assets/svg/sms-shape.svg';
import { ReactComponent as LocationTimeSVG } from '../../../assets/svg/location-time.svg';
import { ReactComponent as LocationPhoneSVG } from '../../../assets/svg/location-phone.svg';
import { ReminderContext } from '../../../context/reminder-context';

const OrderSuccess = () => {
	const { t } = useTranslation();
	const [phoneHovered, setPhoneHovered] = useState(false);
	const [emailHovered, setEmailHovered] = useState(true);
	const reserveValues = useContext(ReminderContext);
	const PhoneIsValid =
		reserveValues.phone && reserveValues.phone.length === 9;
	const EmailIsValid =
		reserveValues.email &&
		/\S+@\S+\.\S+/.test(reserveValues.email.toLowerCase());
	const selectedCenter = reserveValues.selectedCenter;
	const [viewport, setViewport] = useState({
		width: 800,
		height: 300,
		latitude: parseFloat(selectedCenter.map_lan),
		longitude: parseFloat(selectedCenter.map_lon),
		zoom: 12
	});
	const mapConfig = {
		mapStyle: 'https://map.my.ge/style/my/map/style.json',
		onViewportChange: setViewport
	};

	const hoverPhone = () => setPhoneHovered(!phoneHovered);
	const hoverEmail = () => setEmailHovered(!emailHovered);
	// eslint-disable-next-line
	momentLoc.locale('ka');
	return (
		<div className="order-success">
			<div className="order-left main-container">
				<div className="order-success-inner">
					<div className="success-icon">
						<i>
							<SuccessSentSVG />
						</i>
					</div>
					<div className="success-text">
						<h2>{t('reserveSent')}</h2>
						<p>
							{t('willReceive')}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								onClick={() => {
									hoverEmail();
									hoverPhone();
								}}>
								{' '}
								{t('onNumber')}
							</a>{' '}
							{t('and')}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								onClick={() => {
									hoverPhone();
									hoverEmail();
								}}>
								{' '}
								{t('onEmail')}
							</a>
						</p>
					</div>
					<div className="tech-cards">
						<div
							className={`tech-card-item card-hovered ${
								!emailHovered ? 'disabled' : ''
							}`}
							onMouseEnter={() => {
								hoverEmail();
							}}
							onMouseLeave={() => {
								hoverEmail();
							}}>
							<div className="tech-card-icon">
								<i>
									<EmailShapeSVG />
								</i>
							</div>
							<div className="tech-card-details">
								<h6>
									{PhoneIsValid
										? '+995 ' +
										  reserveValues.phone.substr(0, 3) +
										  ' ' +
										  reserveValues.phone.substr(3, 2) +
										  ' ' +
										  reserveValues.phone.substr(5, 2) +
										  ' ' +
										  reserveValues.phone.substr(7, 2)
										: t('phone')}
								</h6>
								<p>{t('newMessageFrom')}</p>
							</div>
						</div>
						<div
							className={`tech-card-item card-hovered ${
								!phoneHovered ? 'disabled' : ''
							}`}
							onMouseEnter={() => {
								hoverPhone();
							}}
							onMouseLeave={() => {
								hoverPhone();
							}}>
							<div className="tech-card-icon">
								<i>
									<SMSShapeSVG />
								</i>
							</div>
							<div className="tech-card-details">
								<h6>
									{EmailIsValid
										? reserveValues.email
										: t('email')}
								</h6>
								<p>{t('newMessageFrom')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="order-right main-container">
				<div className="order-success-inner">
					<div className="selected-location">
						<div className="selected-location-heading">
							<div className="location-icon">
								<img
									src={
										Number(selectedCenter.logos)
											? process.env.REACT_APP_STATIC_URL +
											  'logos/' +
											  selectedCenter.id +
											  '.jpg'
											: process.env.REACT_APP_NO_LOGO
									}
									alt={selectedCenter.title}
								/>
							</div>
							<div className="location-info">
								<h4>{selectedCenter.title}</h4>
								<h6>{selectedCenter.address}</h6>
							</div>
						</div>
						<div className="selected-location-details">
							<div className="detail-item">
								<div className="date-wrapper">
									<i>
										<LocationTimeSVG />
									</i>
									<div className="date">
										{reserveValues.formData &&
											reserveValues.formData.time}{' '}
										-
										{reserveValues.formData &&
											momentLoc(
												reserveValues.formData.date
											).format('D') + ' '}
										{reserveValues.formData &&
											momentLoc(
												reserveValues.formData.date
											).format('MMMM')}
										<span>
											{reserveValues.formData &&
												momentLoc(
													reserveValues.formData.date
												).format('dddd')}
										</span>
									</div>
								</div>
								<div className="count-label">
									{reserveValues.formData &&
										momentLoc(
											reserveValues.formData.date
										).fromNow()}
								</div>
							</div>
							<div className="detail-item">
								<div className="date-wrapper">
									<i>
										<LocationPhoneSVG />
									</i>
									<div className="phone">
										{selectedCenter.phone}
									</div>
								</div>
							</div>
						</div>
						<div className="selected-location-map">
							<ReactMapGL {...viewport} {...mapConfig}>
								<Marker
									key={`marker-${selectedCenter.id}`}
									latitude={parseFloat(
										selectedCenter.map_lan
									)}
									longitude={parseFloat(
										selectedCenter.map_lon
									)}
									captureClick={true}>
									<img src={MarkerIcon} alt="marker" />
								</Marker>
							</ReactMapGL>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderSuccess;
