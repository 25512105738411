import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import chainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import xhrBackend from 'i18next-xhr-backend';

Object.assign(i18n, {
	locales: {
		ka: 'ქართული',
		en: 'English',
		ru: 'Русский'
	}
});

const locales = Object.keys(i18n.locales);

i18n.use(chainedBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection: {
			order: ['path'],
			lookupFromPathIndex: 0,
			checkWhitelist: true
		},
		fallbackLng: locales[0],
		whitelist: locales,
		load: 'languageOnly',
		backend: {
			backends: [LocalStorageBackend, xhrBackend],
			backendOptions: [
				{ expirationTime: 7 * 24 * 60 * 60 * 1000 },
				{
					loadPath: process.env.REACT_APP_LANGS_URL,
					parse: data => data,
					ajax: async function(url, options, callback) {
						const response = await fetch(url);
						const data = await response.json();
						callback(data, { status: 200 });
					}
				}
			]
		}
	});

export default i18n;
