import React, {useState} from 'react';
import InspectChecker from "../../containers/InspectChecker";
import RightInspection from "./RightInspection";

const Content = () => {
    const [selectedComponent, switchSelectedComponent] = useState('inspectionCheckForm');

    return (
        <div className="techinspect-inner">
            <InspectChecker
                selectedComponent={selectedComponent}
                switchSelectedComponent={switchSelectedComponent}
            />
            <RightInspection
                selectedComponent={selectedComponent}
            />
        </div>
    );
};

export default Content;