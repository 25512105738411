import React, { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EmailBG from '../../../assets/svg/email-bg.svg';
import PhoneBg from '../../../assets/svg/phone-bg.svg';
import SmsBg from '../../../assets/svg/sms-bg.svg';
import Send from '../../../assets/svg/send.svg';
import InputError from '../../UI/InputError';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import { ReminderContext } from '../../../context/reminder-context';
import Popup from '../../UI/popups/popup';

const ReminderForm = ({ formData, inspectData, switchBlock }) => {
	const { t } = useTranslation();
	const updPhone = useContext(ReminderContext).setPhoneRem;
	const updEmail = useContext(ReminderContext).setEmailRem;

	const smsCodeBtn = useRef(null);
	const [codeSent, updateCodeSentValue] = useState(false);

	const form = useFormik({
		initialValues: {
			email: '',
			phone: '',
			sms_code: ''
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email(t('invalidEmail'))
				.max(191, t('invalidLength'))
				.required(t('errorRequired')),
			phone: Yup.string()
				.min(9, t('invalidPhone'))
				.max(9, t('invalidPhone'))
				.required(t('errorRequired')),
			sms_code: Yup.string()
				.min(4, t('invalidCode'))
				.max(4, t('invalidCode'))
				.required(t('errorRequired'))
		}),
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true);
			axios
				.post(
					`/savereminder?phone=${values.phone}&email=${values.email}&license_number=${formData.license_number}&date=${inspectData.inspectDate}&sms_code=${values.sms_code}`
				)
				.then(res => {
					if (res.data.statusCode === 1) {
						resetForm();

						// Swal({
						//     icon: "success",
						//     title: res.data.statusMessage,
						//     showConfirmButton: false,
						//     timer: 1000,
						//     onClose(modalElement) {
						//         switchBlock("");
						//     }
						// });
						swal({
							buttons: {},
							content: (
								<Popup
									type={'reminder'}
									title={res.data.statusMessage}
								/>
							),
							showCloseButton: true,
							timer: 2000
						});
					} else {
						// Swal(res.data.statusMessage, {
						//     className: "error-alert"
						// });
						swal({
							buttons: {},
							content: (
								<Popup
									type={'error'}
									title={res.data.statusMessage}
								/>
							),
							showCloseButton: true,
							timer: 2000
						});
					}

					setSubmitting(false);
				});
		}
	});

	const handleCodeSend = () => {
		if (
			smsCodeBtn.current.classList.contains('disabled') ||
			!form.values.phone ||
			form.errors.phone
		) {
			return false;
		}

		axios
			.post('/sendactivationcode?phone=' + form.values.phone)
			.then(res => {
				if (res.data.statusCode === 1) {
					updateCodeSentValue(true);
					updEmail(null);
					updPhone(null);

					swal({
						buttons: {},
						content: (
							<Popup
								type={'success'}
								title={res.data.statusMessage}
							/>
						),
						showCloseButton: true,
						timer: 2000
					});

					setInterval(() => updateCodeSentValue(false), 60000);
				} else {
					swal({
						buttons: {},
						content: (
							<Popup
								type={'error'}
								title={res.data.statusMessage}
							/>
						),
						showCloseButton: true,
						timer: 2000
					});
				}
			});
	};

	return (
		<div className="techinspect-form techinspect-reminder-form">
			<form className="technispect-form" onSubmit={form.handleSubmit}>
				<div
					className={`tech-input ${
						form.touched.email
							? form.errors.email
								? 'error'
								: 'valid'
							: null
					}`}>
					<i>
						<img alt="Email Background" src={EmailBG} />
					</i>
					<input
						type="email"
						name="email"
						placeholder={t('email')}
						onChange={e => {
							form.handleChange(e);
							updEmail(e.target.value);
						}}
						onBlur={form.handleBlur}
						value={form.values.email}
					/>
					{form.touched.email && form.errors.email && (
						<InputError>{form.errors.email}</InputError>
					)}
				</div>
				<div
					className={`tech-input ${
						form.touched.phone
							? form.errors.phone
								? 'error'
								: 'valid'
							: null
					}`}>
					<i>
						<img alt="Phone Background" src={PhoneBg} />
					</i>
					<input
						name="phone"
						type="number"
						placeholder={t('phone')}
						onChange={e => {
							form.handleChange(e);
							updPhone(e.target.value);
						}}
						onBlur={form.handleBlur}
						value={form.values.phone}
					/>
					{form.touched.phone && form.errors.phone && (
						<InputError>{form.errors.phone}</InputError>
					)}
				</div>
				<div className="tech-input-group">
					<div className="tech-input sms-input error">
						<i>
							<img alt="SMS Background" src={SmsBg} />
						</i>
						<input
							type="text"
							name="sms_code"
							placeholder={t('smsCode')}
							autoComplete={'off'}
							onChange={form.handleChange}
							onBlur={form.handleBlur}
							value={form.values.sms_code}
						/>
						{form.touched.sms_code && form.errors.sms_code && (
							<InputError>{form.errors.sms_code}</InputError>
						)}
					</div>
					<button
						ref={smsCodeBtn}
						className={`btn sms-btn ${(!form.values.phone ||
							form.errors.phone ||
							codeSent) &&
							'disabled'}`}
						type="button">
						<span
							className={`send-code ${codeSent && 'fill'}`}
							onClick={() =>
								handleCodeSend(form.values.sms_code)
							}>
							{t('clickForCode')}
							<i>
								<img alt="Send" src={Send} />
							</i>
						</span>
					</button>
				</div>
				<button
					type="submit"
					className={`tech-reminder-btn tech-submit-btn tech-check-btn 
                            ${(form.isSubmitting ||
								Object.entries(form.touched).length < 2 ||
								Object.entries(form.errors).length > 0) &&
								'disabled'}`}>
					{t('activeReminder')}
				</button>
			</form>
		</div>
	);
};

export default ReminderForm;
