import React from 'react';
import { useTranslation } from 'react-i18next';
import TechCenter from '../../Centers/TechCenter';
import FilterBlock from '../FilterBlock';

const TechCentersBlock = props => {
	const { t } = useTranslation();

	return (
		<div className="location-content">
			<FilterBlock setQuery={props.setQuery} />
			<div className="location-items-wrapper">
				<div className="location-items">
					{props.centers.length === 0 ? (
						<p className="main-container-left no-locations">
							{t('noResults')}
						</p>
					) : (
						props.centers.map(center => {
							return (
								<TechCenter
									key={center.id}
									center={center}
									selected={props.selected}
									title={center.title}
									address={center.address}
									logo={center.logo}
									image={center.image}
									phone={center.phone}
									workingHours={center.working_hours}
									startReserve={props.startReseve}
								/>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

export default TechCentersBlock;
