import React from 'react';
import { ReactComponent as SmsSuccessSVG } from '../../../assets/svg/sms-success.svg';
import { ReactComponent as SmsErrorSVG } from '../../../assets/svg/sms-error.svg';
import { ReactComponent as ReminderSuccessSVG } from '../../../assets/svg/reminder-success.svg';
import { ReactComponent as PopupCloseSVG } from '../../../assets/svg/popup-close.svg';
import swal from '@sweetalert/with-react';

const Popup = ({ type, title, text }) => {
	return (
		<div>
			<button className="popup-close" onClick={() => swal.close()}>
				<PopupCloseSVG />
			</button>
			<div className="popup-header">
				{type === 'reminder' ? (
					<ReminderSuccessSVG />
				) : type === 'error' ? (
					<SmsErrorSVG />
				) : type === 'success' ? (
					<SmsSuccessSVG />
				) : null}
			</div>
			<div className="popup-body">
				<h4 className="popup-title">{title}</h4>
				{text ? <p className="popup-text">{text}</p> : null}
				<button className="popup-btn" onClick={() => swal.close()}>
					გასაგებია
				</button>
			</div>
		</div>
	);
};

export default Popup;
