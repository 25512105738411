import React, {useState} from "react";
import Select from 'react-select';
import * as yup from 'yup';
import axios from 'axios';
import {useFormik} from 'formik';
import {useTranslation} from "react-i18next";
import ReactHTMLParser from 'react-html-parser';
import ReserveHeading from "../ReserveHeading";
import OrderFooter from "../OrderFooter";
import {vehicleTypes, months} from "../../../../data";

const Step1 = ({formData, setFormData, nextStep, inspectDataProvided, startReserve}) => {
    const [inspectData, setInspectData] = useState(inspectDataProvided || {exists: false});
    // eslint-disable-next-line no-unused-vars
    const [selectedOption, setSelectedOption] = useState(null);
    const {t} = useTranslation();
    const setDirection = () => {
    };

    const onCategorySelect = (category) => {
        setSelectedOption(category);
        stepOneForm.setTouched({...stepOneForm.touched, category: true});
        stepOneForm.setValues({...stepOneForm.values, category});
    };

    const stepOneForm = useFormik({
        initialValues: formData,
        validationSchema: yup.object({
            license_number: yup
                .string()
                .matches(/(^[a-z]{2}[0-9]{3}[a-z]{2}$)|(^[a-z]{3}[0-9]{3})/ig, {message: t('errorInvalid')})
                .required(t('errorRequired'))
                .max(20),
            category: yup
                .string()
                .required(t('chooseCategory'))
        }),
        onSubmit: (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            setFormData(values);
            if (!inspectData.exists) {
                axios.get(`/getinspectiondate?license_number=` + values.license_number)
                    .then(response => {
                            response.data.exists
                                ? setInspectData(response.data)
                                : setInspectData({
                                    exists: false,
                                    errorMessage: response.data.error || t('inspectCheckError')
                                });

                            setSubmitting(false);
                        }
                    )
                    .catch(err => setErrors(err));
            } else {
                nextStep();
            }
        }
    });

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected || state.isFocused ? '#FD4100' : '#181a26',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            padding: '12px 41px 12px 28px',
            fontFamily: 'FiraGO',
            fontSize: '13px',
        }),
        control: () => ({
            width: '100%',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'FiraGO',
            fontSize: '14px',
            color: 'rgba(#181a26, 0.6)',
            border: '1px solid #dadce0',
            borderRadius: '8px',
            padding: '0 32px 0 78px',

        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {...provided, opacity, transition};
        }
    };

    return (
        <div className="order-details main-container">
            <div className="order-body-wrapper">
                <form className="order-form"  onSubmit={stepOneForm.handleSubmit}>
                <ReserveHeading/>
                <div className="order-body">
                    <div className="order-select">
                        <div className="order-row">
                            <div className="order-col">
                                <div className={`tech-input car-number-input 
                            ${stepOneForm.touched.license_number && stepOneForm.errors.license_number ? 'error' : null}
                            ${!stepOneForm.errors.license_number && stepOneForm.touched.license_number ? 'valid' : null}`}>
                                    <input
                                        type="text"
                                        value={stepOneForm.values.license_number}
                                        onChange={(e) => {
                                            stepOneForm.handleChange(e);
                                            setInspectData({exists: false});
                                        }}
                                        onBlur={stepOneForm.handleBlur}
                                        autoComplete="off"
                                        name="license_number"
                                        placeholder={t('licenseNumber')}
                                    />
                                    {stepOneForm.touched.license_number && stepOneForm.errors.license_number &&
                                    <span className="error-message">{stepOneForm.errors.license_number}</span>
                                    }
                                    {inspectData.errorMessage &&
                                    <span className="error-message">{inspectData.errorMessage}</span>}
                                </div>
                            </div>
                            <div className="order-col">
                                <div className="vehicle__type__select">
                                    <Select
                                        value={stepOneForm.values.category}
                                        onChange={selectedOption => {
                                            onCategorySelect(selectedOption);
                                            setInspectData({exists: false});
                                        }}
                                        options={vehicleTypes}
                                        styles={customStyles}
                                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                        placeholder={t('chooseCategory')}
                                        className="vehicle__type__select"
                                    />
                                    {stepOneForm.touched.category && stepOneForm.errors.category &&
                                    <span className="error-message">{stepOneForm.errors.category}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        {inspectData.exists &&
                        <div className="techinspect-date">
                            <div className="order-row">
                                <div className="order-col">
                                    <h4>
                                        {Number(inspectData.inspectDate.substr(0, 2)) + ' ' +
                                        t(months[Number(inspectData.inspectDate.substr(3, 2)) - 1]) + ', ' +
                                        Number(inspectData.inspectDate.substr(6, 4))} {t('year')}
                                    </h4>
                                </div>
                                <div className="order-col">
                                    <div className="service-price">
                                        <span className="price-label">
                                            {ReactHTMLParser(t('serviceFee'))}
                                        </span>
                                        <div className="price">60 ₾</div>
                                    </div>

                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <OrderFooter
                    submitting={stepOneForm.isSubmitting}
                    setDirection={setDirection}
                    disabled={Object.entries(stepOneForm.touched).length === 0 || Object.entries(stepOneForm.errors).length > 0}
                    title={inspectData.exists ? t('continue') : t('check')}
                    step={1}
                    prevStep={() => startReserve(false)}
                />
            </form>
            </div>
        </div>
    )
};

export default Step1;
