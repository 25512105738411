import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHTMLParser from 'react-html-parser';

const Heading = ({ reminder }) => {
	const { t } = useTranslation();

	return (
		<h2 className="techinspect-heading">
			{reminder
				? ReactHTMLParser(t('reserveHeading'))
				: 'შემახსენე ვიზიტი'}
		</h2>
	);
};

export default Heading;
