import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import axios from 'axios';
import Select from 'react-select';
import { vehicleTypes } from '../../../data';
import { useHistory } from 'react-router';

const InspectionCheckFields = ({
	setFormData,
	switchBlock,
	inspectData,
	setInspectData
}) => {
	const history = useHistory();
	const [classes, updateClasses] = useState([
		'dropdown',
		'car-type-dropdown'
	]);
	const [selectedOption, setSelectedOption] = useState(null);
	const { t } = useTranslation();
	const url = useHistory();
	let urlObj = {};
	if (url.location.search) {
		urlObj = JSON.parse(
			'{"' +
				decodeURI(url.location.search)
					.replace(/"/g, '\\"')
					.replace('?', '')
					.replace(/&/g, '","')
					.replace(/=/g, '":"') +
				'"}'
		);
	}

	useEffect(() => {
		if (urlObj.hasOwnProperty('license_number') && urlObj.license_number) {
			inspectForm.setSubmitting(true);
			setFormData(inspectForm.values);
			axios
				.get(
					`/getinspectiondate?license_number=${urlObj.license_number}`
				)
				.then(response => {
					if (response.data.exists) {
						setInspectData(response.data);
						history.push({
							pathname: '/centers',
							state: {
								ln: urlObj.license_number,
								id: response.data.inspectDate,
								category: {
									value: 'M1',
									label:
										'M1 - მსუბუქი ავტომობილი, არაუმეტეს 8 ადგილისა'
								}
							}
						});
						switchBlock('inspectionDate');
					} else {
						setInspectData({
							exists: false,
							errorMessage:
								response.data.error || t('inspectCheckError')
						});
					}
					inspectForm.setSubmitting(false);
				})
				.catch(err => {
					setInspectData({
						exists: false,
						errorMessage: err
					});
					inspectForm.setSubmitting(false);
				});
		}
		// eslint-disable-next-line
	}, [inspectData.exists]);

	const onSelectHandler = category => {
		setSelectedOption(category);
		inspectForm.setTouched({ ...inspectForm.touched, category: true });
		inspectForm.setValues({ ...inspectForm.values, category });
		updateClasses(
			classes.filter((cls, i) => i !== classes.indexOf('open'))
		);
	};

	const inspectForm = useFormik({
		initialValues: {
			license_number: '',
			category: ''
		},
		validationSchema: yup.object({
			license_number: yup
				.string()
				.matches(/(^[a-z]{2}[0-9]{3}[a-z]{2}$)|(^[a-z]{3}[0-9]{3})/gi, {
					message: t('errorInvalid')
				})
				.required(t('errorRequired'))
				.min(6, t('errorInvalid'))
				.max(7, t('errorInvalid')),
			category: yup.string().required(t('chooseCategory'))
		}),
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(true);
			setFormData(values);
			axios
				.get(
					`/getinspectiondate?license_number=` + values.license_number
				)
				.then(response => {
					if (response.data.exists) {
						setInspectData(response.data);
						switchBlock('inspectionDate');
					} else {
						setInspectData({
							exists: false,
							errorMessage:
								response.data.error || t('inspectCheckError')
						});
					}
					setSubmitting(false);
				})
				.catch(err => {
					setInspectData({
						exists: false,
						errorMessage: err
					});
					setSubmitting(false);
				});
		}
	});

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			color: state.isSelected || state.isFocused ? '#FD4100' : '#181a26',
			backgroundColor: 'transparent',
			cursor: 'pointer',
			padding: '12px 41px 12px 28px',
			fontFamily: 'FiraGO',
			fontSize: '13px'
		}),
		control: () => ({
			width: '100%',
			height: '70px',
			display: 'flex',
			alignItems: 'center',
			fontFamily: 'FiraGO',
			fontSize: '14px',
			color: 'rgba(#181a26, 0.6)',
			border: '1px solid #dadce0',
			borderRadius: '8px',
			padding: '0 32px 0 78px'
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		}
	};

	return (
		<div className="technispect-form">
			<form onSubmit={inspectForm.handleSubmit}>
				<div
					className={`tech-input car-number-input 
                        ${inspectForm.touched.license_number &&
							inspectForm.errors.license_number &&
							'error'}
                        ${inspectForm.values.license_number &&
							!inspectForm.errors.license_number &&
							'valid'}`}>
					<input
						type="text"
						onChange={inspectForm.handleChange}
						maxLength="7"
						autoComplete="off"
						name="license_number"
						value={inspectForm.values.license_number}
						placeholder={t('licenseNumber')}
					/>
					{inspectForm.touched.license_number &&
						inspectForm.errors.license_number && (
							<span className="error-message">
								{inspectForm.errors.license_number}
							</span>
						)}
					{inspectData.errorMessage && (
						<span className="error-message">
							{inspectData.errorMessage}
						</span>
					)}
				</div>
				<div className="vehicle__type__select">
					<Select
						value={selectedOption}
						styles={customStyles}
						onChange={selectedOption =>
							onSelectHandler(selectedOption)
						}
						options={vehicleTypes}
						placeholder={t('chooseCategory')}
						className="vehicle__type__select"
						components={{
							DropdownIndicator: () => null,
							IndicatorSeparator: () => null
						}}
					/>
					{inspectForm.touched.category &&
						inspectForm.errors.category && (
							<span className="error-message">
								{inspectForm.errors.category}
							</span>
						)}
				</div>
				<button
					className={`tech-submit-btn tech-check-btn
                                    ${inspectForm.isSubmitting && 'isLoading'}
                                    ${(Object.entries(inspectForm.touched)
										.length === 0 ||
										Object.entries(inspectForm.errors)
											.length > 0) &&
										'disabled'}`}
					type="submit">
					{t('Check')}
				</button>
			</form>
		</div>
	);
};
export default InspectionCheckFields;
