import React from 'react';

const inputError = (props) => {
    return (
        <span className="error-message">
            {props.children}
        </span>
    );
};

export default inputError;