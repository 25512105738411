import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import OrderSuccess from './OrderSuccess';

const ReserveDetails = props => {
	const [step, setStep] = useState(props.providedData ? 2 : 1);
	const [formData, setFormData] = useState({
		license_number: props.providedData ? props.providedData.ln : '',
		category: props.providedData ? props.providedData.category : '',
		first_name: '',
		email: '',
		phone: '',
		sms_code: '',
		date: '',
		time: ''
	});

	const nextStep = () => setStep(prev => prev + 1);
	const prevStep = () => setStep(prev => prev - 1);

	switch (step) {
		case 1:
			return (
				<Step1
					startReserve={props.startReseve}
					formData={formData}
					setFormData={setFormData}
					nextStep={nextStep}
					inspectDataProvided={
						props.providedData
							? {
									exists: true,
									inspectDate: props.providedData.id
							  }
							: null
					}
				/>
			);
		case 2:
			return (
				<Step2
					formData={formData}
					setFormData={setFormData}
					nextStep={nextStep}
					prevStep={prevStep}
				/>
			);
		case 3:
			return (
				<Step3
					formData={formData}
					setFormData={setFormData}
					nextStep={nextStep}
					prevStep={prevStep}
					finishReserve={props.setFinished}
				/>
			);
		default:
			return <OrderSuccess />;
	}
};

export default ReserveDetails;
