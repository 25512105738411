import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {ReactComponent as IphoneNotificationSVG} from "../../../assets/svg/iphone-notification.svg";
import TechIphoneImage from "../../../assets/images/tech-iphone.png";
import { ReactComponent as EmailShapeSVG } from "../../../assets/svg/email-shape.svg";
import {ReactComponent as SmsShapeSVG} from "../../../assets/svg/sms-shape.svg";
import {ReminderContext} from "../../../context/reminder-context";

const PhoneSideContent = (props) => {
    const {t} = useTranslation();
    const reminderValues = useContext(ReminderContext);
    const PhoneIsValid = reminderValues.phoneRem && reminderValues.phoneRem.length === 9;
    const EmailIsValid = reminderValues.emailRem && /\S+@\S+\.\S+/.test(reminderValues.emailRem.toLowerCase());

    return (
        <div className="phone-bg">
            <div className="techinspect-iphone">
                <i className={`iphone-notification ${(!PhoneIsValid || !EmailIsValid) && 'disabled'}`}>
                    <IphoneNotificationSVG/>
                </i>
                <img src={TechIphoneImage} alt="Tech Iphone"/>
                <div className={`tech-iphone-item left-item ${!EmailIsValid && 'disabled'}`}>
                    <div className="tech-card-icon">
                        <i><EmailShapeSVG/></i>
                    </div>
                    <div className="tech-card-details">
                        <h6>{EmailIsValid ? reminderValues.emailRem : t('email')}</h6>
                        <p>{t('newMessageFrom')}</p>
                    </div>
                </div>
                <div className={`tech-iphone-item right-item ${!PhoneIsValid && 'disabled'}`}>
                    <div className="tech-card-icon">
                        <i><SmsShapeSVG/></i>
                    </div>
                    <div className="tech-card-details">
                        <h6>{PhoneIsValid ? '+995 ' + reminderValues.phoneRem.substr(0, 3)
                            + ' ' + reminderValues.phoneRem.substr(3, 2)
                            + ' ' + reminderValues.phoneRem.substr(5, 2)
                            + ' ' + reminderValues.phoneRem.substr(7, 2) : t('phone')}</h6>
                        <p>{t('newMessageFrom')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhoneSideContent;