import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import InspectionCheckFields from '../components/MainPage/Inspection/InspectionCheckFields';
import InspectionDate from '../components/MainPage/Inspection/InspectionDate';
import ReminderForm from '../components/MainPage/Reminder/ReminderForm';
import Heading from '../components/MainPage/Heading';

const InspectChecker = props => {
	const [formData, setFormData] = useState(null);
	const [inspectData, setInspectData] = useState({ exists: false });

	let currentBlock = '';

	switch (props.selectedComponent) {
		case 'inspectionCheckForm':
			currentBlock = (
				<InspectionCheckFields
					formData={formData}
					setFormData={setFormData}
					switchBlock={props.switchSelectedComponent}
					inspectData={inspectData}
					setInspectData={setInspectData}
				/>
			);
			break;
		case 'inspectionDate':
			currentBlock = (
				<InspectionDate
					history={props.history}
					data={inspectData}
					formData={formData}
					switchBlock={props.switchSelectedComponent}
				/>
			);
			break;
		case 'reminderForm':
			currentBlock = (
				<ReminderForm
					formData={formData}
					inspectData={inspectData}
					switchBlock={props.switchSelectedComponent}
				/>
			);
			break;
		default:
			currentBlock = (
				<InspectionCheckFields
					formData={formData}
					setFormData={setFormData}
					switchBlock={props.switchSelectedComponent}
					inspectData={inspectData}
					setInspectData={setInspectData}
				/>
			);
	}

	return (
		<div className="left-techinspect main-container">
			<Heading reminder={props.selectedComponent !== 'reminderForm'} />
			{currentBlock}
		</div>
	);
};

export default withRouter(InspectChecker);
