import React from 'react';
import Content from './Content';
import Footer from "../Layout/Footer";

class MainPage extends React.Component {
    render() {
        return (
            <div>
                <Content/>
                <Footer/>
            </div>
        );
    }
}

export default MainPage;