import React, { useState, useEffect } from 'react';
import '../style.scss';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import i18next from 'i18next';
import { useCookies } from 'react-cookie';

import LocationMap from '../components/Location/Map/LocationMap';
import TechCentersBlock from '../components/Location/TechCentersBlock/TechCenters/TechCentersBlock';
import ReserveDetails from '../components/Location/ReserveBlock/ReserveDetails';
import OrderSuccess from '../components/Location/ReserveBlock/OrderSuccess';
import { langMap } from '../data';

const LocationPageHandler = props => {
	const [cookies, setCookie] = useCookies(['language']);
	const params = new URLSearchParams(props.location.search);
	if (params.get('ln') && params.get('id')) {
		props.history.push({
			pathname: '/centers',
			state: {
				license_number: params.get('ln'),
				inspectDate: params.get('id')
			}
		});
	}
	const [techCenters, setTechCenters] = useState([]);

	i18next.on('languageChanged', function(lng) {
		setCookie('language', lng);
	});
	let selectedLang = cookies.language || i18next.language;

	useEffect(() => {
		let centers = [];

		axios
			.post(process.env.REACT_APP_API_SERVER + '/GetTechCenters')
			.then(res => {
				let centers = res.data.centers;
				let centersTransformed = [];

				for (let center of centers) {
					let centerName = JSON.parse(center.name)[
						langMap[i18next.language]
					];
					let weekDayStartTime = Object.values(
						JSON.parse(center.working_hours)
					)[0].start_time.trim();
					let weekDayEndTime = Object.values(
						JSON.parse(center.working_hours)
					)[0].end_time.trim();
					let weekDayHours = weekDayStartTime + '-' + weekDayEndTime;
					let satDayStartTime = Object.values(
						JSON.parse(center.working_hours)
					)[5].start_time.trim();
					let satDayEndTime = Object.values(
						JSON.parse(center.working_hours)
					)[5].end_time.trim();
					let satDayHours = satDayStartTime + '-' + satDayEndTime;
					let sunDayStartTime = Object.values(
						JSON.parse(center.working_hours)
					)[6].start_time.trim();
					let sunDayEndTime = Object.values(
						JSON.parse(center.working_hours)
					)[6].end_time.trim();
					let sunDayHours = sunDayStartTime + '-' + sunDayEndTime;

					centersTransformed.push({
						...center,
						title: centerName,
						weekDayHours,
						satDayHours,
						sunDayHours
					});
				}

				setTechCenters(centersTransformed);
			});

		setTechCenters(centers);
	}, [selectedLang]);
	const [mapClosed, setMapClosed] = useState(false);
	const [reserveStarted, setReserveStarted] = useState(null);

	const [query, setQuery] = useState('');
	const [isReserveFinished, setIsReserveFinished] = useState(false);
	const filteredCenters = techCenters.filter(
		center =>
			center.title.toLowerCase().indexOf(query) !== -1 ||
			center.address.toLowerCase().indexOf(query) !== -1
	);
	return (
		<div className="location-inner">
			{isReserveFinished ? (
				<OrderSuccess />
			) : (
				<React.Fragment>
					<div
						className={`left-location ${
							mapClosed ? 'map-close' : undefined
						}`}>
						{!reserveStarted ? (
							<TechCentersBlock
								setQuery={setQuery}
								centers={filteredCenters}
								startReseve={setReserveStarted}
							/>
						) : (
							<ReserveDetails
								providedData={props.location.state}
								setFinished={setIsReserveFinished}
								startReseve={setReserveStarted}
							/>
						)}
					</div>
					<LocationMap
						mapClicked={() => setMapClosed(!mapClosed)}
						centers={filteredCenters}
					/>
				</React.Fragment>
			)}
		</div>
	);
};

export default withRouter(LocationPageHandler);
