import React from 'react';
import { useTranslation } from 'react-i18next';

const FilterBlock = props => {
	const { t } = useTranslation();

	const charMap = {
		a: 'ა',
		b: 'ბ',
		d: 'დ',
		e: 'ე',
		v: 'ვ',
		z: 'ზ',
		T: 'თ',
		i: 'ი',
		k: 'კ',
		l: 'ლ',
		m: 'მ',
		n: 'ო',
		o: 'პ',
		J: 'ჟ',
		r: 'რ',
		s: 'ს',
		t: 'ტ',
		u: 'უ',
		f: 'ფ',
		p: 'ფ',
		q: 'ქ',
		R: 'ღ',
		y: 'ყ',
		S: 'შ',
		C: 'ჩ',
		c: 'ც',
		Z: 'ძ',
		w: 'წ',
		W: 'ჭ',
		x: 'ხ',
		j: 'ჯ',
		h: 'ჰ'
	};

	const handleChange = char => {
		char = char.split('');
		let query = '';

		char.forEach(
			c => (query += !Object.values(charMap).includes(c) ? charMap[c] : c)
		);

		props.setQuery(query);
	};

	return (
		<div className="location-search main-container-left">
			<input
				onChange={event => handleChange(event.target.value)}
				placeholder={t('searchQuery')}
			/>
		</div>
	);
};

export default FilterBlock;
