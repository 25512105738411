import React, { useState, useContext } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import ReserveHeading from '../ReserveHeading';
import DatePicker, { registerLocale } from 'react-datepicker';
import ka from 'date-fns/locale/ka';
import { useTranslation } from 'react-i18next';
import OrderFooter from '../OrderFooter';
import { addDays } from 'date-fns';
import { ReminderContext } from '../../../../context/reminder-context';
import swal from '@sweetalert/with-react';
import Popup from '../../../UI/popups/popup';

registerLocale('ka', ka);

const Step3 = ({ formData, setFormData, prevStep, finishReserve }) => {
	const [chosenDate, setChosenDate] = useState(new Date());
	const updFormData = useContext(ReminderContext).setFormData;
	const selectedCenter = useContext(ReminderContext).selectedCenter;
	const chosenOffers = useContext(ReminderContext).chosenOffers;
	const setChosenOffers = useContext(ReminderContext).setChosenOffers;
	const [availableTimes, setAvailableTimes] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [direction, setDirection] = useState('back');
	const { t } = useTranslation();

	const dateSelectHandler = date => {
		stepThreeForm.setValues({ ...stepThreeForm.values, date });
		setChosenDate(date);
		axios
			.get(
				process.env.REACT_APP_API_SERVER +
					'/GetTechCenterAvailableTimes?date=' +
					moment(date).format('Y-MM-DD') +
					'&tech_id=' +
					selectedCenter.id +
					'&day=' +
					moment(date).day()
			)
			.then(res => setAvailableTimes(res.data));
	};

	const stepThreeForm = useFormik({
		initialValues: formData,
		validationSchema: yup.object({
			date: yup.date().required(t('chooseDate')),
			time: yup.string().required(t('chooseTime'))
		}),
		onSubmit: (values, { setSubmitting, setValues }) => {
			setSubmitting(true);
			setFormData(values);
			finishReserve(true);
			let offers = chosenOffers.map(offer => offer.id);
			const offersQuery = offers.length ? `&offers=${[...offers]}` : '';
			updFormData({ ...formData, ...stepThreeForm.values });
			const date = moment(stepThreeForm.values.date).format('YYYY-MM-DD');

			axios
				.post(
					process.env.REACT_APP_API_SERVER +
						'/SaveReserve?name=' +
						formData.first_name +
						'&email=' +
						formData.email +
						'&phone=' +
						formData.phone +
						'&date=' +
						date +
						'&license_number=' +
						formData.license_number +
						'&time=' +
						stepThreeForm.values.time +
						'&center_id=' +
						selectedCenter.id +
						'&offers=' +
						offersQuery
				)
				.then(res => {
					if (res.data.statusCode === 1) {
						finishReserve(true);
						setChosenOffers([]);
					} else {
						setSubmitting(false);
						swal({
							buttons: {},
							content: (
								<Popup
									type={'error'}
									title={res.data.statusMessage}
								/>
							),
							showCloseButton: true,
							timer: 2000
						});
					}
				});
		}
	});
	return (
		<div className="order-details main-container">
			<div className="order-body-wrapper">
				<form
					className="order-form"
					onSubmit={stepThreeForm.handleSubmit}>
					<div className="datepicker-heading">
						<ReserveHeading />
					</div>
					<div className="order-body">
						<div className="order-row datepicker-row">
							<div className="order-col">
								<DatePicker
									onChange={date => dateSelectHandler(date)}
									selected={chosenDate}
									minDate={addDays(new Date(), 1)}
									maxDate={new Date().setDate(
										new Date() + 30
									)}
									showDisabledMonthNavigation
									calendarClassName="tech__datepicker"
									locale="ka"
									inline
								/>
							</div>
							<div className="order-col">
								<div className="time__picker">
									<div
										className={`time__picker__title ${
											stepThreeForm.errors.date ||
											stepThreeForm.errors.time
												? 'error-color'
												: 'selected-time'
										}`}>
										{stepThreeForm.errors.date ||
											stepThreeForm.errors.time ||
											stepThreeForm.values.time ||
											t('time')}
									</div>
									<div className="time__picker__choose__time">
										{t('availableTime')}:
									</div>
									<div className="time__picker__body">
										{availableTimes.length > 0 &&
											availableTimes.map(time => {
												return (
													<span
														key={time}
														className={
															stepThreeForm.values
																.time === time
																? 'active'
																: undefined
														}
														onClick={() =>
															stepThreeForm.setValues(
																{
																	...stepThreeForm.values,
																	time
																}
															)
														}>
														{time}
													</span>
												);
											})}
									</div>
								</div>
							</div>
						</div>
					</div>
					<OrderFooter
						submitting={stepThreeForm.isSubmitting}
						disabled={
							stepThreeForm.isSubmitting ||
							Object.entries(stepThreeForm.errors).length > 0
						}
						title={t('reserve')}
						prevStep={prevStep}
						setDirection={setDirection}
						step={3}
					/>
				</form>
			</div>
		</div>
	);
};

export default Step3;
