import React, { useContext, useEffect, useState } from 'react';
import { ReminderContext } from '../../context/reminder-context';

const OfferItem = ({ offer, unlimitedOffer }) => {
	const { id, title, description, usage_limit, photo } = offer;
	const [, setIsActive] = useState(false);
	const { chosenOffers, selectOffer } = useContext(ReminderContext);
	const isSelected = chosenOffers.find(el => el.id === id);

	useEffect(() => {
		//if (unlimitedOffer) {
		selectOffer({ target: { checked: true } }, id, usage_limit);
		setIsActive(true);
		//}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="offers-col">
			<label
				className={`offers-checkbox ${isSelected ? '' : 'disabled'}`}>
				<input
					type="checkbox"
					checked={isSelected || usage_limit === -1}
					onClick={e => {
						setIsActive(e.target.checked);
						selectOffer(e, id, usage_limit);
					}}
				/>
				<div className="offers-card">
					<span className="checkmark" />
					<div className="card-heading">
						<div className="card-logo">
							<img
								src={
									process.env.REACT_APP_STATIC_SHORT_URL +
									photo
								}
								alt={title}
							/>
						</div>
						<h6 className="card-title">{title}</h6>
					</div>
					<p className="card-text">{description}</p>
				</div>
			</label>
		</div>
	);
};

export default OfferItem;
