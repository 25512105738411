import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/myauto_inspect.svg';
import i18next from 'i18next';
import { useCookies } from 'react-cookie';
import { ReminderContext } from '../../context/reminder-context';

const Header = () => {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const [cookies, setCookie] = useCookies(['language']);
	const setChosenOffers = useContext(ReminderContext).setChosenOffers;

	const changeLanguage = lng => {
		setCookie('language', lng);
		i18n.changeLanguage(lng);
	};

	const langs = {
		ka: 'GEO',
		en: 'ENG',
		ru: 'RUS'
	};

	return (
		<header className="location-header techninspect-header main-container">
			<div className="techinspect-logo">
				<Link onClick={() => setChosenOffers([])} to="/">
					{' '}
					<img src={Logo} alt="Logo" />
				</Link>
			</div>
			<ul className="breadcrumbs">
				<li>
					<Link onClick={() => setChosenOffers([])} to="/">
						{t('mainPage')}
					</Link>
				</li>
				{location.pathname === '/centers' ? (
					<li>
						<Link onClick={() => setChosenOffers([])} to="/centers">
							{t('location')}
						</Link>
					</li>
				) : location.pathname === '/offers' ? (
					<li>
						<Link onClick={() => setChosenOffers([])} to="/offers">
							{`შეთავაზებები`}
						</Link>
					</li>
				) : null}
			</ul>
			<div className="header-right">
				<ul className="header-menu">
					<li>
						<Link onClick={() => setChosenOffers([])} to="/centers">
							{t('location')}
						</Link>
					</li>
					<li>
						<Link
							onClick={() => setChosenOffers([])}
							to="/offers">{`შეთავაზებები`}</Link>
					</li>
				</ul>
				<div className="tech-lang-dropdown">
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a className="tech-lang-dropdown-toggle active">
						{langs[cookies.language || i18next.language]}
					</a>
					<div className="tech-lang-dropdown-menu">
						{Object.entries(langs)
							.filter(lng => lng[0] !== i18next.language)
							.map(lang => (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a
									key={lang}
									onClick={() => {
										changeLanguage(lang[0]);
									}}>
									{lang[1]}
								</a>
							))}
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
